import carouselImg1 from "../../../media/warehouse-with-boxes_container_16617.jpg";
import carouselImg2 from "../../../media/b3.jpg";
import carouselImg3 from "../../../media/infoBg.jpg";
import carouselImg4 from "../../../media/Logistics-Company-scaled.jpg";

export const SliderData = [
	{
		image: carouselImg3,
		title: "Экспорт и Импорт",
		description: "Сколько будет растаможа вашего товара?",
		buttonTitle: "оставьте заявку",
		key: 0,
	},
	{
		image: carouselImg4,
		title: "Экспорт и Импорт",
		description: "Сколько будет растаможа вашего товара?",
		buttonTitle: "оставьте заявку",
		key: 1,
	},
	{
		image: carouselImg1,
		title: "Экспорт и Импорт",
		description: "Сколько будет растаможа вашего товара?",
		buttonTitle: "оставьте заявку",
		key: 2,
	},
	{
		image: carouselImg2,
		title: "Экспорт и Импорт",
		description: "Сколько будет растаможа вашего товара?",
		buttonTitle: "оставьте заявку",
		key: 3,
	},
];
